import React from "react"
import BgImg from "gatsby-background-image"
import * as styles from "./simple-hero.module.scss"
const SimpleHero = ({ banner, tag, category }) => {
  const { background_color, background_image, heading } = banner.elements

  const bgOverlay =
    background_color.value[0] !== undefined
      ? background_color.value[0].name.replace(/\s+/g, "")
      : "Gray"
  if (background_image && background_image.value.length > 0) {
    return (
      <BgImg
        fluid={background_image.value[0].fluid}
        className={`${styles.simpleHero}`}
        alt={background_image.value[0].description}
      >
        <div className={`blackBg ${styles.overlay}`}></div>
        <section className={`container ${styles.bannerContent}`}>
          {tag || category ? (
            <h1>{tag ? tag : `Articles tagged with ${category}`}</h1>
          ) : (
            <h1>{heading.value}</h1>
          )}
        </section>
      </BgImg>
    )
  } else {
    return (
      <section className={`${styles.simpleHeroTextOnly} ${bgOverlay}Bg`}>
        <div className={`container ${styles.bannerContent}`}>
          {tag || category ? (
            <h1>{tag ? tag : category}</h1>
          ) : (
            <h1>{heading.value}</h1>
          )}
        </div>
      </section>
    )
  }
}

export default SimpleHero
