import React from "react"
import { Link } from "gatsby"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import BgImg from "gatsby-background-image"
import Img from "gatsby-image"
import * as styles from "./hero.module.scss"
const Hero = ({ banner, phone, customCTA }) => {
  const {
    secondary_image,
    heading,
    subheading,
    buttons,
    tagline,
    background_color,
    desktop,
    mobile,
  } = banner

  const sources = [
    mobile.value[0].fluid,
    {
      ...desktop.value[0].fluid,
      media: `(min-width: 768px)`,
    },
  ]
  const secondaryExists = secondary_image?.value[0]
  return (
    <BgImg
      fluid={sources}
      className={styles.hero}
      alt={desktop.value[0].description}
    >
      <div className={`blackBg ${styles.opacity}`}></div>
      <section
        className={
          secondaryExists
            ? `container ${styles.bannerContent}`
            : `container ${styles.centeredBannerContent}`
        }
      >
        <h1>{heading.value}</h1>
        {subheading && subheading.value !== "" && (
          <RichTextElement value={subheading.value} className />
        )}
        <div className={styles.homePageBannerLinks}>
          {buttons.value.map((button, i) => {
            const { button_text, linked_page } = button.elements
            const { slug } = linked_page.value[0].elements
            let className = "primary-button"
            if (i === 1) className = "white-accent-ghost-button"
            return (
              <Link key={button.id} to={slug.value} className={className}>
                {button_text.value}
              </Link>
            )
          })}
        </div>
        <div>
          {tagline.value ? (
            <p className={styles.smallText}>{tagline.value}</p>
          ) : (
            <p className={styles.smallText}>{customCTA}</p>
          )}
        </div>
      </section>
      {secondaryExists && (
        <Img
          fluid={secondary_image.value[0].fluid}
          className={styles.secondary}
          alt={secondary_image.value[0].description}
        />
      )}
    </BgImg>
  )
}

export default Hero
